<template>
  <b-modal :visible="show" :hide-header="true" centered id="modal-criar-senha" size="md" :hide-footer="true"
    no-close-on-backdrop>
    <!-- <div class="float-right"><img @click="hide" class="close" src="img/x-icon.svg" /></div> -->
    <div class="row text-center">
      <div class="col-sm-12 col-12 mt-2">
        <h4 class="title-senha mb-4 title-bold">Crie uma senha para o seu login</h4>

        <ValidationObserver ref="dados">
          <form>
            <div class="field mb-4 mt-2">
              <ValidationProvider name="senha" rules="required|min:6|password_rules" mode="passive"
                v-slot="{ errors, classes }">
                <div class="field">
                  <input :type="showPassword ? 'text' : 'password'" class="input" :style="senha && 'color: black'"
                    :class="classes" v-model="senha" @focus="cleardados" maxlength="100" autocomplete="nope" />
                  <label :class="senha && 'filled'">Digite sua senha</label>
                  <img class="hide-show" @click="togglePasswordVisibility"
                    :src="showPassword ? '/img/olho-aberto.svg' : '/img/olho-fechado.svg'" />

                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>
        <ul class="group-list">
          <li class="list">Sua senha precisa ter no mínimo 6 caracteres.</li>
          <li class="list">Sua senha deve conter pelo menos 1 número.</li>
          <li class="list">Sua senha deve conter pelo menos 1 caractere especial.</li>
        </ul>
        <button class="btn confirm" :disabled="loading" @click.prevent="handle">
          <span class="text">Criar senha</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import axios from "axios";
import config from "../../config";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  props: ["show", "seguro"],
  data() {
    return { config, formatacaoMoeda2, senha: undefined, loading: false, showPassword: false };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async handle() {
      const valid = await this.$refs.dados.validate();
      if (!valid) return;

      await this.dados();
    },
    cleardados() {
      this.$nextTick(() => {
        this.$refs.dados.reset(); // Reset validation errors
      });
    },
    async dados() {
      try {

        this.loading = true;

        var data = {
          nome: this.seguro.nomeCliente,
          email: this.seguro.emailCliente,
          telefone: this.seguro.telefoneCliente,
          cpf: this.seguro.cpfCliente,
          dataNascimento: this.seguro.dataNascimentoCliente,
          siglaUF: this.seguro.ufCliente,
          senha: this.senha
        }

        await axios.post(`${config.API_URLV2}/cliente/`, data);

        this.loading = false;

        this.senha = null;

        this.$emit("sucesso");

      } catch (error) {
        this.loading = false;
      }
    },
    hide() {
      this.senha = null;
      this.$emit("hide");
    },
  },
};
</script>

<style>
.group-list {
  padding-left: 20px;
  margin-top: 20px;
}

.list {
  font-size: 14px;
  text-align: left;
  color: #667085;
}

#modal-criar-senha___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 10px;
  padding-top: 15px;
}

.field {
  position: relative;
}

label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 22px;
  transition: 0.2s ease all;
  font-size: 14px;
  color: #78849e;
}

.hide-show {
  position: absolute;
  right: 15px;
  top: 22px;
  transition: 0.2s ease all;
  cursor: pointer;
}

.filled {
  top: 2px !important;
  color: #4fa935;
}

*:focus {
  outline: 0;
}

input:focus::placeholder {
  color: transparent;
}

input:focus~label {
  top: 2px;
  color: #4fa935;
  font-weight: bold;
}

.input {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 64px;
  border: 1px solid #E2E8F0;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 16px 0px 16px;
  border-radius: 12px;
  background: #fff;

}

.ghost {
  text-decoration: none;
  border: 1px solid;
  border-radius: 15px;
  padding: 15px;
}

.title-bold {
  font-weight: 800;
}

.close {
  width: 15px;
  cursor: pointer;
}

.vw-20 {
  width: 20vw;
}

.voltar {
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.confirm {
  font-size: 18px;
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  border: 1px solid #4FA935;
  background: #4FA935;
  color: #fff;
}

.title-senha {
  color: #425994;
}

.sub-title-senha {
  color: #78849E;
}

@media (max-width: 768px) {

  .title-senha {
    font-size: 18px !important;
    font-weight: bold;
  }

  .sub-title-senha {
    font-size: 14px !important;
  }

  .sub-title {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
  }

  .vw-m-60 {
    width: 30vw;
  }

  #modal-criar-senha___BV_modal_content_ {
    padding: 0 !important;
    padding-top: 0 !important;
  }
}
</style>
